import XHR from '@/request/http'
const $h = new XHR()
/**
 * 模板相关
 * 
 * 
 */

// url
const url = {
    create:'/admin/v1/reflections/create', //发布教学反思
}

export async function create(data)
{
    return $h.request(url.create,data,'POST')
}