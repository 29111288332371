

<template>
    <div class="screen_main">
        <div class="mob_nav"><MobNav @on-click="show=true"/></div>
        <div class="d-flex h-full">
            <el-row class="flex-1" :gutter="10" style="height: 100%;">
                <el-col :md="12" style="height: 47%;margin-bottom: 1%;">
                    <div class="box">
                        <div class="line">总得分</div>
                        <TotalComponent :value="finalScore" :people="people"/>
                    </div>
                </el-col>
                <el-col :md="12" style="height: 47%;margin-bottom: 1%;">
                    <div class="box">
                        <div class="line">评课分类分析</div>
                        <OneSunChart :data="one_sun_options.data"/>
                    </div>
                </el-col>
                <!-- <el-col :md="12" style="height: 47%;margin-bottom: 1%;">
                    <div class="box">
                        <div class="line">教师指标分析</div>
                        <OneRadarChart :indicator="one_radar_options.dataset.indicator" :series="one_radar_options.series"/>
                    </div>
                </el-col>
                <el-col :md="12" style="height: 47%;margin-bottom: 1%;">
                    <div class="box">
                        <div class="line">得分分析</div>
                        <OnePicbarChart :xAxisData="one_picbar_options.xAxis" :seriesData="one_picbar_options.series"/>
                    </div>
                </el-col> -->
                <el-col :md="24" style="height: 47%;margin-bottom: 1%;">
                    <div style="background-color: #ffffff;height: 100%;position: relative;">
                        <div class="box">
                            <div class="line">得分分析</div>
                            <PicBar :data="rule_obj"/>
                        </div>
                    </div>
                </el-col>
                

                <el-col :md="24" style="margin-bottom: 1%;">
                    <div style="background-color: #ffffff;height: 100%;position: relative;">
                        <div class="box">
                            <!-- <div class="line">得分分析 </div> -->
                            <el-table
                                :data="rule_lists"
                                style="width: 100%">
                                <el-table-column prop="name" label="指标名称" width="260"></el-table-column>
                                <el-table-column prop="expertScore" label="人评得分" width="130" align="center"></el-table-column>
                                <el-table-column prop="machineScore" label="机评得分" width="130" align="center">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.machineScore }}/{{ scope.row.maxScore }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="" label="ai打分依据">
                                    <template slot-scope="scope">
                                        <div style="white-space: pre-line;" v-html="scope.row.remark"></div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </el-col>
                
                <el-col :md="24">
                    <div style="background-color: #ffffff;height: 100%;position: relative;margin-bottom: 30px;;">
                        <div class="box">
                            <el-table
                                :data="summary_lists"
                                :show-header="false"
                                style="width: 100%">
                                <el-table-column prop="name" label="" width="100px"></el-table-column>
                                <el-table-column prop="value" label=""></el-table-column>
                            </el-table>
                        </div>
                    </div>
                </el-col>

            </el-row>

        


            <div class="d-flex a-center portrait_nav screen_nav">
                <div style="position: fixed;right: 0;bottom: 0;top: 0;right: 10px;width: 50px;display: flex;align-items: center;">
                    <Nav :params="{lesson_id:lesson_id,teacher_id:teacher_id}" @change="changeNav"/></div>
                </div>
                
        </div>
        <ReflectEditor 
            :show="show" 
            :showBtns="teacher_id == $store.state.user.id" 
            :content="form.content" 
            :returns="analysisResult"
            @on-cancel="handleCancel" @on-submit="handleSubmit"/>
    </div>
</template>
<script>
import TotalComponent from './comp/total.vue'
import OneSunChart from './comp/onesunChart.vue'
import OneRadarChart from './comp/oneradarChart.vue'
import PicBar from './comp/picBar.vue'
import {portrait,lesson_chart} from '@/request/api/lession.js'
import MobNav from '@/components/MobileMenu.vue'
import Nav from './comp/nav.vue'
import ReflectEditor from './comp/ReflectEdit.vue'
import {create} from '@/request/api/reflect.js'
export default {
    components:{
        TotalComponent,
        OneSunChart,
        // OneRadarChart,
        PicBar,
        Nav,
        MobNav,
        ReflectEditor
    },
    data(){
        return{
            teacher_id:'', //教师id
            lesson_id:'',//课程id
            picbar_options:{
                date: [],
                
            },
            one_radar_options:{
                series:[],
                dataset:{
                    indicator:[]
                }
            },
            one_picbar_options:{
                series:[],
                xAxis:[]
            },
            one_sun_options:{
                data:[]
            },
            finalScore:0, //总得分
            people:0,//评课人数
            rule_obj:{}, //人机对比数据
            rule_lists:[],
            show:false,
            form:{
                content: "",
                formatContent: "",
                lessonId: ""
            },
            analysisResult:'',
            summary_lists:[
                {name:'建议',value:'无'},
                {name:'总结',value:'无'}
            ]
        }
    },
    created(){
        this.teacher_id = this.$route.query.teacher_id || ''
        this.lesson_id = this.$route.query.lesson_id || ''
        this.init()
    },
    methods:{
        init(){
            if(!this.lesson_id) return;
            this.get_lesson_portrait()
        },
        //changeNav
        changeNav(handlerName){
            switch(handlerName) {
                case 'reflect':
                    this.show = true
                    break;
                default:
                    break;
            }
        },
        // ============教学反思================
        handleSubmit(data){
            this.form.content = data.html
            this.form.formatContent = data.text
            this.form.lessonId = this.lesson_id
            create(this.form).then(res=>{
                this.show = false
                if(res.code!=0) return this.$notify.error({title:'错误',message: res.msg});
                this.$notify({title: '成功',message: res.msg,type: 'success'});
            })
        },
        handleCancel(){
            this.show=false
        },
        //====================================

        get_lesson_chart(){
            var radar_data = []
            var indicator_data = []
            var group_name_lists = [];
            lesson_chart(this.lesson_id).then(res=>{
                // let data = res.data.list
                // data.forEach(item=>{
                //     radar_data.push(item.finalScore)
                //     indicator_data.push({
                //         name:item.groupName,
                //         max:item.maxScore
                //     })
                //     group_name_lists.push(item.groupName)
                // })
                this.one_radar_options.dataset.indicator = res.indicator_data
                this.one_radar_options.series.push({
                    name: '得分',
                    type: 'radar',
                    data: [
                        {
                            value: res.radar_data,
                            name: '得分'
                        }
                    ]
                
                })


                // //得分项
                this.one_picbar_options.xAxis = res.group_name_lists
                this.one_picbar_options.series.push({
                    name: 'hill',
                    type: 'pictorialBar',
                    barCategoryGap: '-130%',
                    // symbol: 'path://M0,10 L10,10 L5,0 L0,10 z',
                    symbol: 'path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z',
                    itemStyle: {
                        opacity: 0.5
                    },
                    emphasis: {
                        itemStyle: {
                            opacity: 1
                        }
                    },
                    data: res.radar_data,
                    z: 10
                })
            })
        },


        //课程画像 （sun chart）
        get_lesson_portrait(){
            var arr = [];
            portrait(this.lesson_id).then(res=>{
                this.finalScore = res.finalScore
                this.people = res.res.data.reviewCount
                this.one_sun_options.data = res.arr
                this.rule_obj = res.rule_obj
                this.rule_lists = res.rule
                if(res.res.data.reflections) {
                    this.form.content =res.res.data.reflections.content  //反思教学html内容
                    this.analysisResult = res.res.data.reflections.analysisResult
                }

                //建议/总结
                let summary = res.res.data.summary
                if(summary) {
                    this.summary_lists[0].value = summary.suggest
                    this.summary_lists[1].value = summary.summary
                }
                // let data = res.data.reviewData
                // let expert = data.expertRules //人评
                // let machine = data.machineRules //机评
                // let finalScore = data.finalScore // 总得分
                // if(expert) {
                //     this.one_sun_options.data.push({
                //         name:"人评",
                //         itemStyle: {
                //             color: '#E53B00'
                //         },
                //         children:composSun(expert)
                //     })
                // }
                
                // if(machine){
                //     this.one_sun_options.data.push({
                //         name:"机评",
                //         itemStyle: {
                //             color: '#0052DA'
                //         },
                //         children:composSun(machine)
                //     })
                // }

                // this.finalScore = finalScore

            })
        },
        
    }
}
</script>
<style scoped lang="less">
.bg-white {
    background-color: #fff;
}
.line {
    padding-left: 10px;
    border-left: 4px solid #409EFF;
    font-size: 14px;
}
.box {
    background-color: #fff;
    padding: 15px;
    height: 100%;
    box-sizing: border-box;
}
</style>