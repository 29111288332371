<template>
    <el-dialog
    title="教学反思"
    :visible.sync="showA"
    :close-on-click-modal="false"
    :width="width"
    top="5vh"
    :append-to-body="true"
    :before-close="handleClose">
        <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="10">
                <div style="background-color: rgba(0,148,33,0.15);height: 100%;padding:0 10px 10px 10px;margin-bottom: 10px;">
                    <div style="font-size: 16px;font-weight: bold;padding: 10px 0;">模型反馈结果</div>
                    <span style="line-height: 1.5;text-align: justify;white-space: pre-wrap;" v-html="returns"></span>
                </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="14">
                <div style="border: 1px solid #ccc;">
                    <Toolbar
                        style="border-bottom: 1px solid #ccc"
                        :editor="editor"
                        :defaultConfig="toolbarConfig"
                        :mode="mode"
                    />
                    <Editor
                        style="height: 500px; overflow-y: hidden;"
                        v-model="html"
                        :defaultConfig="editorConfig"
                        :mode="mode"
                        @onCreated="onCreated"
                    />
                </div> 
            </el-col>
        </el-row>

        <div v-if="showBtns" class="controls d-flex" style="margin-top: 20px;">
            <el-button type="primary" class="flex-1" @click="handleSubmit">立即提交</el-button>
            <el-button type="plain" @click="handleClose">取消</el-button>
        </div>
    </el-dialog>
    
</template>
<script>
import Vue from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
export default Vue.extend({
    components: { Editor, Toolbar },
    props:{
        show:{ //弹窗显隐
            type:Boolean,
            default:true
        },
        content:{ //文本内容
            type:String,
            default:''
        },
        showBtns:{ //提交按钮组
            type:Boolean,
            default:true,
        },
        returns:{  //反馈结果
            type:String,
            default:'无'
        }
    },
    data() {
        return {
            showA:this.$props.show,
            editor: null,
            html: '',
            toolbarConfig: { 
                excludeKeys:['video','insertVideo','uploadVideo','uploadImage','insertImage','emotion'],
            },
            editorConfig: { placeholder: '请输入内容...' },
            mode: 'default', // or 'simple'
            width:'1220px',
        }
    },
    watch:{
        content:function(val) {
            this.html = val
        },
        show:function(val){
            this.showA = val
        },
        showBtns:function(val) {
            this.disabled()
        }
    },
    methods: {
        onCreated(editor) {
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
            this.disabled()
        },
        handleClose(){
            this.showA = false
            this.$emit('on-cancel')
        },
        handleSubmit(){
            var obj = {
                html:this.editor.getHtml(),
                text:this.editor.getText(),
            }
            this.$emit('on-submit',obj)
        },
        disabled(){ //禁用编辑器
            if(!this.$props.showBtns) {
                this.editor.disable()
            }
        }
    },
    mounted() {
        window.onresize=()=>{
            this.width = document.documentElement.clientWidth<1024 ?'100%':'1220px'
        }
    },
    
    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    }
})
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
</style>